
@use '@angular/material' as mat;
@use '../colours.scss' as colours;

$typography1: mat.m2-define-typography-config(
  $font-family: 'Poppins',
  $headline-5: mat.m2-define-typography-level(32px, 38px, 600), /* H1 */
  $headline-6: mat.m2-define-typography-level(24px, 32px, 600), /* H2 */
  $subtitle-1: mat.m2-define-typography-level(20px, 32px, 600), /* H3 */
  $subtitle-2: mat.m2-define-typography-level(18px, 32px, 400), /* H4 */
  $body-1: mat.m2-define-typography-level(16px, 24px, 400),
  $body-2: mat.m2-define-typography-level(16px, 24px, 600),
  $caption: mat.m2-define-typography-level(13px, 13px, 500), /* error texts and hints */
  $button: mat.m2-define-typography-level(16px, 24px, 600)
);

$mobile-typography: mat.m2-define-typography-config(
  $font-family: 'Poppins',
  $headline-5: mat.m2-define-typography-level(24px, 28px, 600), /* H1 */
  $headline-6: mat.m2-define-typography-level(18px, 24px, 600), /* H2 */
  $subtitle-1: mat.m2-define-typography-level(16px, 20px, 600), /* H3 */
  $subtitle-2: mat.m2-define-typography-level(14px, 16px, 400), /* H4 */
  $body-1: mat.m2-define-typography-level(16px, 24px, 400),
  $body-2: mat.m2-define-typography-level(16px, 24px, 600),
  $caption: mat.m2-define-typography-level(13px, 13px, 500), /* error texts and hints */
  $button: mat.m2-define-typography-level(16px, 24px, 600)
);

@mixin typograhpy() {

  @media screen and (max-width: 599.99px) and (orientation: portrait), (max-width: 959.99px) and (orientation: landscape) {
    @include mat.all-component-typographies($mobile-typography);
  }
  @media screen and (min-width: 600px) and (max-width: 839.99px) and (orientation: portrait), (min-width: 960px) and (max-width: 1279.99px) and (orientation: landscape) {
    @include mat.all-component-typographies($typography1);
  }
  @media screen and (min-width: 840px) and (orientation: portrait), (min-width: 1280px) and (orientation: landscape) {
    @include mat.all-component-typographies($typography1);
  }

  .mat-typography {
    color: colours.$dark-primary-text;
  }
  a {
    color: colours.$dark-primary-text;
    font-weight: 600;
    &.disabled {
      pointer-events: none;
      opacity: .6;
    }
  }
  h1, h2, h3, h4, div, span {
    &[color="accent"] {
      color: colours.get-color-from-palette(colours.$apollo-accent-palette, 500)
    }
    &[color="primary"] {
      color: colours.get-color-from-palette(colours.$apollo-primary-palette, 500)
    }
    &[color="tertiary"] {
      color: colours.get-color-from-palette(colours.$apollo-tertiary-palette, 500)
    }
  }
  h1.xl {
    font-size: 40px;
    line-height: 48px;
  }

  .content-body {
    p:last-of-type {
      margin-bottom: 0 !important;
    }
  }
  mat-icon.s-4 {
    font-size: 1rem !important;
    height: 1rem !important;
    width: 1rem !important;
  }
  mat-icon.s-6 {
    font-size: 1.5rem !important;
    height: 1.5rem !important;
    width: 1.5rem !important;
  }
  mat-icon.s-8 {
    font-size: 2rem !important;
    height: 2rem !important;
    width: 2rem !important;
  }
  mat-icon.s-9 {
    font-size: 2.25rem !important;
    height: 2.25rem !important;
    width: 2.25rem !important;
  }
}
// used for resident image upload only
mat-icon.x2 {
  font-size: 48px;
  height: 48px;
  width: 48px;
}