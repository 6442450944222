
@use '../../../assets/theming/colours.scss' as colours;

mat-chip-row,
mat-chip-option {
  height: 44px !important;
  background-color: #fff !important;
  border-radius: 8px !important;
  border: 1px solid colours.get-color-from-palette(colours.$apollo-neutral-palette, 200);
  font-size: 14px !important;
  font-weight: 500 !important;
  &.mat-mdc-chip-selected {
    border: 2px solid colours.get-color-from-palette(colours.$apollo-accent-palette, 500);
    .mdc-evolution-chip__checkmark {
      background-color: colours.get-color-from-palette(colours.$apollo-accent-palette, 500) !important;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      svg {
        width: 16px;
        height: 16px;
      }
    }
    .mat-mdc-chip-action-label {
      color: colours.get-color-from-palette(colours.$apollo-neutral-palette, 500) !important;
    }
  }
}