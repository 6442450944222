/* You can add global styles to this file, and also import other style files */
@use 'assets/theming/theme.scss';
@tailwind base;
@tailwind utilities;

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Poppins;
  -webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape while allowing user zoom */
  // overflow: hidden;

  /* this counteracts the rubber band effect */
  // &.is-responsive {
  //   position: fixed;
  //   overflow: hidden;
  //   width: 100%;
  // }
}

* {
  box-sizing: border-box;
}