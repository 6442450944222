@use '@angular/material' as mat;
@use 'colours.scss' as colours;
@use 'material-icons/iconfont/filled.css';
@use 'material-icons/iconfont/outlined.css';
@use 'helpers/columns.scss'; // DEPRECATED, try to replace with tailwind
@use 'helpers/typography.scss' as typography;
@use 'helpers/elevation.scss';
@use 'components/mat-form-field.scss';
@use 'components/mat-button.scss';
@use 'components/mat-table.scss';
@use 'components/mat-chip.scss';
@use 'components/mat-checkbox.scss';
@use 'components/layout.scss';
@use 'components/dialog.scss';
@use 'components/form.scss';
@use 'components/mat-snackbar.scss';
@use 'components/page-loader.scss';
@use 'components/fieldset.scss';
@use 'components/thumbnail.scss';

@include mat.core();
@include typography.typograhpy();

$apollo-app-primary: mat.m2-define-palette(
  colours.$apollo-primary-palette,
  500
);
$apollo-app-accent: mat.m2-define-palette(
  colours.$apollo-accent-palette,
  500,
  100,
  500
);
$apollo-app-warn: mat.m2-define-palette(mat.$m2-red-palette);

$my-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $apollo-app-primary,
      accent: $apollo-app-accent,
      warn: $apollo-app-warn,
    ),
  )
);

@include mat.all-component-themes($my-theme);


// only used for resident image upload
.outline-primary-200 {
  outline-color: colours.get-color-from-palette(colours.$apollo-primary-palette, 200) !important;
}