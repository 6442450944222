
@use '../colours.scss' as colours;
@use '../helpers/responsive.scss' as responsive;

$icon-color: colours.get-color-from-palette(colours.$apollo-neutral-palette, 200);

@mixin dialog-base($dark) {
  width: 50% !important;
  max-width: 640px;
  background-color: #fff;


  @include responsive.mobile {
    width: 100% !important;
    max-width: 100% !important;
  }
  @if ($dark) {
    & {
      color: #fff;
    }
    .close mat-icon {
      color: #fff;
    }
  } @else {
    .close mat-icon {
      color: #000;
    }
  }
  [mat-dialog-header] {
    padding: 16px 24px;
    font-weight: 400;
    font-size: 20px;
    @include responsive.mobile {
      padding: 16px;
    }
    @if ($dark) {
      & {
        background-color: colours.get-color-from-palette(
          colours.$apollo-neutral-palette,
          500
        );
      }
    }
    h2 {
      margin: 0;
    }
    .close-button {
      button {
        margin-right: -8px;
        font-weight: 300;
        text-transform: uppercase;
        @if ($dark) {
          color: #fff;
        } @else {
          color: #000;
        }
      }
    }
  }
  [mat-dialog-content] {
    padding:  24px;
    padding-bottom: 0;
    text-align: left;
  }
  [mat-dialog-actions] {
    justify-content :initial;
    padding:  24px;
  }
}


.cdk-overlay-container {
  .apollo-backdrop {
    background-color: #fff;
    opacity: 0.75 !important;
  }

  mat-dialog-container {
    .mat-mdc-dialog-surface {
      position: relative;
      background-color: transparent;
      @include responsive.mobile {
        border-radius: 0;
      }
      .close {
        position: absolute;
        top: 12px;
        right: 12px;
        &[mat-dialog-close] {
          top: 20px;
          right: 24px;
        }

        cursor: pointer;
        text-transform: uppercase;
        font-weight: 200;
      }
    }
  }

  .default-dialog {
    @include dialog-base(false);
  }

  .default-dark-dialog {
    @include dialog-base(true);
  }


  /* Specific Dialog Styling */
  .subscription-dialog,
  .concurrency-dialog {
    background-color: colours.get-color-from-palette(colours.$apollo-neutral-palette, 500);
    color: #fff;
    .mat-mdc-dialog-title,
    .mat-mdc-dialog-content {
      color: #fff;
    }
    a {
      color: #fff;
    }
  }

  .full-width-dialog,
  .search-dialog {
    .mat-mdc-dialog-surface  {
      padding: 16px;
    }
    color: #000 !important;
    width: 100%  !important;
    height: 100% !important;
    max-width: 100% !important;
    background-color: rgba(255,255,255,.95);
    [mat-dialog-content] {
      padding: 24px 0 0 0;
      text-align: left !important;
      max-height: inherit;
    }
  }
}