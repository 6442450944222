@use '../colours.scss' as colours;
form {
  mat-label {
    &.required::after {
      content: '*';
      margin-left: 4px;
      color: colours.get-color-from-palette(colours.$apollo-tertiary-palette, 500);
    }
  }
}

/* custom.css or global styles file */
.input-no-spinner::-webkit-outer-spin-button,
.input-no-spinner::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-no-spinner {
  -moz-appearance: textfield;
}