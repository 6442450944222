@use '@angular/material' as mat;
@use 'sass:map';
@use 'sass:meta';
@use 'sass:color';

@function get-contrast-color-from-palette($palette, $hue) {
  @return map.get(map.get($palette, contrast), $hue);
}

@function get-color-from-palette($palette, $hue: default, $opacity: null) {
  // If hueKey is a number between zero and one, then it actually contains an
  // opacity value, so recall this function with the default hue and that given opacity.
  @if meta.type-of($hue) == number and $hue >= 0 and $hue <= 1 {
    @return get-color-from-palette($palette, default, $hue);
  }

  // We cast the $hue to a string, because some hues starting with a number, like `700-contrast`,
  // might be inferred as numbers by Sass. Casting them to string fixes the map lookup.
  $color: if(map.has-key($palette, $hue), map.get($palette, $hue), map.get($palette, $hue + ''));

  @if (meta.type-of($color) != color) {
    // If the $color resolved to something different from a color (e.g. a CSS variable),
    // we can't apply the opacity anyway so we return the value as is, otherwise Sass can
    // throw an error or output something invalid.
    @return $color;
  }
  @return rgba($color, if($opacity == null, color.opacity($color), $opacity));
}



$apollo-neutral-palette: (
  50: #ECEDEC,
  75: #F5F7F8,
  100: #D9DFE3,
  200: #8193A2,
  300: #4E687D,
  400: #284761,
  500: #022745,
  600: #02233E,
  700: #011D36,
  800: #01172E,
  900: #010E1F
);


// $dark-primary-text: colours.get-color-from-palette($apollo-neutral-palette, 500);
$dark-primary-text: get-color-from-palette($apollo-neutral-palette, 500);
$light-primary-text: white;

$apollo-deep-purple-palette: (
  50: #EAE7F4,
  100: #CBC2E4,
  200: #B494DF,
  300: #8670C1,
  400: #755DBA,
  500: #5233A6,
  600: #4B2E9E,
  700: #412795,
  800: #38208B,
  900: #28147B,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text
  )
);

$apollo-teal-palette: (
  50: #E0F3F3,
  100: #B3E0E0,
  200: #91D0C6,
  300: #4DB8B8,
  400: #1AABA1,
  500: #009999,
  600: #009191,
  700: #008686,
  800: #007C7C,
  900: #006B6B,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text
  )
);

$apollo-aubergine-palette: (
  50: #F4E8F1,
  100: #E4C5DC,
  200: #D982B6,
  300: #C077AE,
  400: #BF57A5,
  500: #A53D8B,
  600: #9D3783,
  700: #932F78,
  800: #8A276E,
  900: #791A5B,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text
  )
);
// palettes
$apollo-primary-palette: $apollo-deep-purple-palette;
$apollo-accent-palette: $apollo-teal-palette;
$apollo-tertiary-palette: $apollo-aubergine-palette;
$apollo-red-palette: mat.$m2-red-palette;

// common colour classes
.bg-neutral-500 {
  background-color: get-color-from-palette($apollo-neutral-palette, 500) !important;
}
.text-neutral-500 {
  color: get-color-from-palette($apollo-neutral-palette, 500) !important;
}
.text-accent-500 {
  color: get-color-from-palette($apollo-accent-palette, 500) !important;
}
.text-primary-500 {
  color: get-color-from-palette($apollo-primary-palette, 500) !important;
}
.text-primary-400 {
  color: get-color-from-palette($apollo-primary-palette, 400) !important;
}

.text-warn {
  color: get-color-from-palette($apollo-red-palette, 500) !important;
}
.bg-primary-50 {
  background-color: get-color-from-palette($apollo-primary-palette, 50) !important;
}
.bg-primary-100 {
  background-color: get-color-from-palette($apollo-primary-palette, 100) !important;
}
.bg-accent-100 {
  background-color: get-color-from-palette($apollo-accent-palette, 100) !important;
}
.border-primary-100 {
  border-color: get-color-from-palette($apollo-primary-palette, 100)
}
